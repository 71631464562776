import { render, staticRenderFns } from "./shouye.vue?vue&type=template&id=2ddc3ddb&scoped=true&"
import script from "./shouye.vue?vue&type=script&lang=js&"
export * from "./shouye.vue?vue&type=script&lang=js&"
import style0 from "./shouye.vue?vue&type=style&index=0&id=2ddc3ddb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ddc3ddb",
  null
  
)

export default component.exports